import {
  Box,
  Container,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { mastrListAdmin } from "../data/Lists";
import { Outlet, useNavigate } from "react-router-dom";
import { Store } from "../context/DataStore";

export default function Dashboard() {
  const [s, setS] = useState([]);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { adminState, userInfo } = Store();
  const permotionList = () => {
    if (userInfo?.permissions?.includes("8")) {
      setS(mastrListAdmin);
      if (window.location.href.includes("?redirect=mail")) {
        navigate("/dashboard/orders");
      }
    } else {
      if (userInfo?.permissions) {
        const i = mastrListAdmin.filter((ele) =>
          userInfo?.permissions?.find((x) => x === ele.value)
        );
        if (i) {
          setS(i);
          if (window.location.href.includes("?redirect=mail")) {
            navigate("/dashboard/orders");
          } else {
            console.log(i[0]?.path);
            navigate(`${i[0]?.path}`);
          }
          navigate(`${i[0]?.path}`);
        }
      }
    }
  };
  useEffect(() => {
    if (userInfo ) {
      permotionList();
    }else {
      navigate("/");
    }

  }, []);

  return (
    <Box mt={5}>
      {/* <Helmet>
        <title> </title>
      </Helmet> */}
      <Container>
        <Grid container spacing={2}>
          <Grid
            item
            md={adminState ? 3 : 0}
            xs={12}
            px={2}
            sx={{ display: adminState ? "inline-block" : "none" }}
          >
            <List>
              {s.map((ele, index) => (
                <ListItemButton
                  key={index}
                  onClick={() => navigate(ele?.path)}
                  sx={{
                    color:
                      ele?.path === window.location.pathname
                        ? "#2391BC"
                        : "GrayText",
                    backgroundColor:
                      ele?.path === window.location.pathname && "#9BC6F8",
                    fontFamily: "Tajawal"
                    // color: "#2391BC"
                  }}
                >
                  <ListItemIcon>{ele?.icon}</ListItemIcon>
                  <Typography fontFamily={"Tajawal"}>
                    {i18n.t(ele?.title)}
                  </Typography>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid
            item
            md={adminState ? 9 : 12}
            xs={12}
            px={2}
            mb={3}
            sx={{ bgcolor: "#f5f6fa", borderRadius: "20px" }}
          >
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
