import { Close, Edit, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import RTLBox from "../RTLBox";
import { LoadingButton } from "@mui/lab";
import { UplodeImages } from "./UplodeImages";
import axios from "axios";
import { Store } from "../../context/DataStore";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function AddNewUnit({ data, fun }) {
  const navigate =useNavigate()
  const [element, setElement] = useState(data?data:"");
  const [items, setItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [Loading, setLoadind] = useState(false);
  const { i18n } = useTranslation();
  const [num, setNum] = useState(data ? data.description.length : 0);
  const { files, options, setFiles } = Store();
  const formData = new FormData();
  const [cities, setCities] = useState([]);
  const validationSchema = yup.object({
    name: yup.string().required(i18n.t("RealEstateUnits.form.name_required")),
    // type: yup
    //   .string()
    //   .min(2)
    //   .required(i18n.t("RealEstateUnits.form.type_required")),
    city: yup.string().required(i18n.t("RealEstateUnits.form.cityـrequired")),
    area: yup.string().required(i18n.t("RealEstateUnits.form.areaـrequired")),
    disc: yup
      .string()
      .required(i18n.t("RealEstateUnits.form.disc_uniteـrequired")),
    area_2: yup
      .string()
      .required(i18n.t("RealEstateUnits.form.area2ـrequired")),
    price: yup.string().required(i18n.t("RealEstateUnits.form.priceـrequired")),
    unit_address: yup
      .string()
      .required(i18n.t("RealEstateUnits.form.priceـrequired"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: element?.name || "",
      dev_name: element?.developer_name || "",
      type: element?.item_type || "",
      city: element?.city?.id || 1,
      area: element?.area || "",
      disc: element?.description || "",
      price: element?.price || "",
      area_2: element?.space || "",
      unit_address: element?.location || ""
    },
    onSubmit: async (values) => {
      setLoadind(true);
      if (files.length > 0) {
        Object.keys(files).map((index) => {
          return formData.append(`items[]`, files[index]);
        });
      }
      if (data) {
        await axios
          .put(
            `/real-estate-devs/update_item/${data.id || element.id}?name=${
              values.name
            }&city_id=${values.city}&space=${values.area_2}&price=${
              values.price
            }&description=${values.disc}&area=${values.area}&location=${
              values.unit_address
            }&item_type_id=${values.type.id}`,
            formData,
            { headers: options }
          )
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar(`${res.data.message}`, { variant: "success" });
              fun();
              setLoadind(false);
              setFiles([]);
              closeHandler();
              window.location.reload();
            }
          })
          .catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
            console.log(err);
            setLoadind(false);
          });
      } else {
        await axios
          .post(
            `/real-estate-devs/add_item?name=${values.name}&city_id=${values.city}&space=${values.area_2}&price=${values.price}&description=${values.disc}&area=${values.area}&location=${values.unit_address}&item_type_id=${values.type}`,
            formData,
            { headers: options }
          )
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar(`${res.data.message}`, { variant: "success" });
              setLoadind(false);
              closeHandler();
              window.location.reload();
              fun();
            }
          })
          .catch((err) => {
            enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
            console.log(err);
            setLoadind(false);
          });
      }
    }
  });
  const getCities = async () => {
    await axios
      .get("/lookups/cities", { headers: options })
      .then((res) => {
        setCities(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getItems = async () => {
    await axios
      .get("/lookups/item-types", { headers: options })
      .then((res) => {
        setItems(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getElement = async () => {
    await axios
      .get(`/real-estate-devs/items/search/${data.id}`, { headers: options })
      .then((res) => {
        // console.log(res.data.data[0]);
        setElement(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const closeHandler = () => {
    formik.handleReset();
    setFiles([])

    setNum(0)
    setOpen(false);
  };
  const openHandler = () => {
    // if (data) {
    //   getElement().then(() => {
    //     formik.values.name = element.name;
    //     formik.values.dev_name = element.developer_name;
    //     formik.values.type = element.item_type;
    //     formik.values.city = element.city.id;
    //     formik.values.area = element.area;
    //     formik.values.price = element.price;
    //     formik.values.disc = element.description;
    //     formik.values.area_2 = element.space;
    //     formik.values.unit_address = element.location;
    //   });
    // }
    getItems();
    setOpen(true);
  };
  const setDisc = (val) => {
    formik.values.disc = val;
    setTimeout(() => {
      setNum(formik.values.disc.length);
    }, 1000);
  };
  useEffect(() => {
    getCities();
  }, []);
  useEffect(() => {
    if (data) {
      getElement();
    }
  }, []);
  return (
    <React.Fragment>
      {data ? (
        <IconButton onClick={openHandler}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          onClick={openHandler}
          variant="contained"
          size="small"
          sx={{
            bgcolor: "#CCDFF2",
            color: "#44A5FF",
            ":hover": { bgcolor: "#44A5FF", color: "#CCDFF2" }
          }}
        >
          {i18n.t("RealEstateUnits.Add_new_property")}
        </Button>
      )}

      <Dialog open={open} onClose={closeHandler}>
        <Box p={2}>
          <Tooltip title="Close">
            <IconButton onClick={closeHandler}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>

        <DialogTitle
          sx={{
            my: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            fontFamily={"Tajawal-b"}
            component={"span"}
            variant="h6"
            borderRadius={2}
            textAlign={"center"}
            width={"100%"}
            p={1}
          >
            {data
              ? i18n.t("RealEstateUnits.form.unite_edit")
              : i18n.t("RealEstateUnits.Add_new_property")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box px={2}>
            <form onSubmit={formik.handleSubmit}>
              <RTLBox>
                <List>
                  <ListItem sx={{ width: "100%", gap: 1 }}>
                    <Box width={"100%"} display={"flex"} gap={2}>
                      <TextField
                        size="small"
                        fullWidth
                        defaultValue={formik.values.name}
                        variant="outlined"
                        label={i18n.t("RealEstateUnits.form.name")}
                        name="name"
                        inputProps={{ type: "text" }}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        // placeholder={i18n.t("AddNewClient.name_hint")}
                      />
                      <Box width={"100%"}>
                        <FormControl fullWidth>
                          <InputLabel id="type">
                            {i18n.t("RealEstateUnits.form.type")}{" "}
                          </InputLabel>
                          <Select
                            size="small"
                            fullWidth
                            id="type"
                            defaultValue={formik.values.type.id}
                            name="type"
                            onChange={formik.handleChange}
                            label={i18n.t("RealEstateUnits.form.type")}
                          >
                            {items?.map((ele, index) => (
                              <MenuItem key={index} value={ele.id}>
                                {ele.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </ListItem>
                  <ListItem sx={{ width: "100%", gap: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel id={"company_type_label"}>
                        {i18n.t("RealEstateUnits.form.city")}{" "}
                      </InputLabel>
                      <Select
                        fullWidth
                        defaultValue={formik.values.city || 1}
                        id="company_type"
                        name="city"
                        size="small"
                        labelId="company_type_label"
                        label={i18n.t("RealEstateUnits.form.city")}
                        onChange={formik.handleChange}
                      >
                        {cities?.map((ele, i) => (
                          <MenuItem value={ele.id} key={i}>
                            {" "}
                            {ele.title}{" "}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      size="small"
                      fullWidth
                      label={i18n.t("RealEstateUnits.form.area")}
                      variant="outlined"
                      name="area"
                      defaultValue={formik.values.area}
                      inputProps={{ type: "text" }}
                      onChange={formik.handleChange}
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      helperText={formik.touched.area && formik.errors.area}
                    />
                  </ListItem>
                  <ListItem>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        {i18n.t("RealEstateUnits.form.unite_des")}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={1}>
                          <Grid item md={6}>
                            <TextField
                              fullWidth
                              name="area_2"
                              size="small"
                              defaultValue={formik.values.area_2}
                              onChange={formik.handleChange}
                              label={i18n.t("RealEstateUnits.form.area2")}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {i18n.language === "enUS" ? (
                                      <Typography>
                                        m<sup>2</sup>
                                      </Typography>
                                    ) : (
                                      <Typography>
                                        م<sup>2</sup>
                                      </Typography>
                                    )}
                                  </InputAdornment>
                                )
                              }}
                              error={
                                formik.touched.area_2 &&
                                Boolean(formik.errors.area_2)
                              }
                              helperText={
                                formik.touched.area_2 && formik.errors.area_2
                              }
                            />
                          </Grid>
                          <Grid item md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              name="price"
                              defaultValue={formik.values.price}
                              label={i18n.t("RealEstateUnits.form.price")}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.price &&
                                Boolean(formik.errors.price)
                              }
                              helperText={
                                formik.touched.price && formik.errors.price
                              }
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              fullWidth
                              size="small"
                              name="unit_address"
                              defaultValue={formik.values.unit_address}
                              label={i18n.t("RealEstateUnits.form.google_link")}
                              onChange={formik.handleChange}
                              error={
                                formik.touched.unit_address &&
                                Boolean(formik.errors.unit_address)
                              }
                              helperText={
                                formik.touched.unit_address &&
                                formik.errors.unit_address
                              }
                            />
                          </Grid>
                          <Grid item md={12}>
                            <TextField
                              fullWidth
                              size="small"
                              multiline
                              autoComplete={"disc"}
                              defaultValue={formik.values.disc}
                              rows={4}
                              name="disc"
                              // onKeyDown={()=>setNum(formik.values.disc.length)}
                              label={i18n.t("RealEstateUnits.form.disc_unite")}
                              onChange={(e) => {
                                setDisc(e.target.value);
                              }}
                              error={
                                formik.touched.disc &&
                                Boolean(formik.errors.disc)
                              }
                              helperText={
                                formik.touched.disc && formik.errors.disc
                              }
                              placeholder={i18n.t("AddNewClient.dics_hint")}
                            />
                            <Typography
                              color={
                                formik.values.disc.length > 300
                                  ? "green"
                                  : "red"
                              }
                            >
                              {num}/300
                            </Typography>
                          </Grid>
                        </Grid>
                        {data ? (
                          <UplodeImages
                            data={element}
                            fun={getElement}
                            set={setOpen}
                          />
                        ) : (
                          <UplodeImages />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </ListItem>
                </List>
                <Box width={"100%"} display={"flex"} justifyContent={"end"}>
                  <LoadingButton
                    loading={Loading}
                    type="submit"
                    sx={{
                      borderRadius: "15px 50px",
                      bgcolor: "#2391BC",
                      fontFamily: "Tajawal-b",
                      width: "120px"
                    }}
                    variant="contained"
                  >
                    {i18n.t("login.send")}
                  </LoadingButton>
                </Box>
              </RTLBox>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
