import {
  Box,
  IconButton,
  InputBase,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { MapOutlined, SearchOutlined} from "@mui/icons-material";
import AddNewPastner from "./AddNewPastner";
import axios from "axios";
import {Store} from "../../context/DataStore";
import {avatar} from "../../img";
import {enqueueSnackbar} from "notistack";
import StuteDailog from "./StuteDailog";
import DeleteDailog from "./DeleteDailog";

export default function TablePartner({statistics}) {
  const [resSearch, setResSearch] = useState(null);
  const {i18n} = useTranslation();
  const {options , partnerData, setPartnerData} = Store();

  const getData = async () => {
    await axios
      .get("/partners/all", {headers: options})
      .then((res) => {
        if (res.status === 200) {
          setPartnerData(res.data.data);
          statistics()
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
        console.log(err);
      });
  };

  const changeHandel = async (e, p) => {
    setPartnerData(null)
    setResSearch(null)
    await axios
      .get(`/partners/all?page=${p}`, {headers: options})
      .then((res) => {
        setPartnerData(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (resSearch === "" ||  undefined) {
      getData();
    }
  }, [resSearch]);

  const getSearch = async (key) => {
    await axios
      .get(`/common/search-user/${key}`, {headers: options})
      .then((res) => {
        setResSearch(res.data);
      })
      .catch((err) => {
        setResSearch("");
      });
  };
  useEffect(() => {
},[partnerData])
  return (
    <>
      <Box
        width={"100%"}
        bgcolor={"#D9E0E2"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        py={1}
        my={3}
      >
        <Box
          borderRadius={2}
          width={"50%"}
          p={0.5}
          bgcolor={"#fff"}
          display={"flex"}
          alignItems={"center"}
          gap={2}
        >
          <SearchOutlined sx={{color: "GrayText"}} />
          <InputBase
            fullWidth
            placeholder={i18n.t("search.titles.dev")}
            onChange={(e) => {
              getSearch(e.target.value);
            }}
          />
        </Box>
        <AddNewPastner statistics={statistics} fun={getData} />
      </Box>

      <TableContainer >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("AddNewClient.company_logo")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AddNewClient.partner_name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("AddNewClient.website")}
              </TableCell>
              <TableCell align="center">{i18n.t("login.phone")}</TableCell>
              <TableCell align="center">
                {i18n.t("AddNewClient.google_link")}
              </TableCell>
              <TableCell align="center">
              <Typography>{i18n.t("All_tables.stute")}</Typography>
              </TableCell>
              <TableCell align="center">
                {i18n.t("AddNewClient.edit")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerData?.data?.map((ele, index) => (
              <TableRow key={index}>
                <TableCell>
                  <img
                    src={ele.small_logo || avatar}
                    alt={ele.name}
                    width={50}
                  />
                </TableCell>
                <TableCell>{ele.name}</TableCell>
                <TableCell>
                  <Typography
                    component={"a"}
                    href={ele.website}
                    target="_blank"
                    color='#09c'

                  >
                    {ele.website}
                  </Typography>
                </TableCell>
                <TableCell>{ele.mobile}</TableCell>
                <TableCell>
                  <IconButton
                    href={ele.location_link}
                    target="_blank"
                  >
                    <MapOutlined color='primary'  />
                  </IconButton>
                </TableCell>
                <TableCell>
                <StuteDailog
                    statistics={statistics}
                    user_id={ele.id}
                    stutes={ele.status}
                    title={"stsatus_titles.sales"}
                    role={ele.role}

                  />
                </TableCell>
                <TableCell align="center">
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <AddNewPastner items={ele} fun={getData} />
                    <DeleteDailog
                      mobile={ele.mobile}
                      fun={getData}
                      message={"delete_messages.sales"}
                      name={ele.name}
                      statistics={statistics}

                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
        >
          <Stack spacing={2} sx={{direction: "ltr"}}>
            <Pagination
              count={partnerData?.meta?.last_page}
              variant="outlined"
              shape="rounded"
              page={partnerData?.meta?.current_page}
              onChange={changeHandel}
            />
          </Stack>
        </Box>
      </TableContainer>
    </>
  );
}
