import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { contactus } from "../img";
import { useTranslation } from "react-i18next";
import RTLBox from "../components/RTLBox";
import { Store } from "../context/DataStore";
import * as yup from "yup";
import {useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { useSnackbar } from "notistack";

export default function Contactus() {
  const {enqueueSnackbar} =useSnackbar()
  const { i18n } = useTranslation();
  const regex = /(05|01)[0-9]{8}/;
  const {options} =Store()

  const { setShow } = Store();
  useEffect(() => {
    setShow(false);
  }, []);

  const v = yup.object({
    message: yup.string().required("الرسالة مطلوبة"),
    mobile: yup
      .string()
      .matches(regex, i18n.t("g.vaild_phone"))
      .max(10, i18n.t("g.vaild_phone_number"))
      .required(i18n.t("AddNewClient.phone_required")),
    full_name: yup.string().required("الاسم الكامل مطلوب"),
    email: yup
      .string()
      .email("البريد الإلكتروني غير صحيح")
      .required("البريد الإلكتروني مطلوب")
  });
  const formk = useFormik({
    initialValues: {
      message: "",
      mobile: "",
      full_name: "",
      email: ""
    },
    validationSchema: v,
    onSubmit:async (values) => {
     await axios.post("/common/contact-us" , values , {headers:options}).then((res)=>{
      if (res.status === 200) {
        formk.resetForm();
        enqueueSnackbar(res.data.message, {variant: "success"});
        
      }
     }).catch((err)=>{
       enqueueSnackbar(err.response.data.message, {variant: "error"});
     })
    }
  });
  return (
    <Box height={"100%"} position={"relative"} pb={5}>
      <Typography
        fontFamily={"Tajawal-b"}
        fontWeight={800}
        component={"h1"}
        variant="h5"
        textAlign={"center"}
        py={2}
      >
        {i18n.t("HeaderMenu.connect")}
      </Typography>

      <Box py={5}>
        <Container sx={{ height: "" }}>
          <Grid container spacing={1} justifyContent={"space-between"}>
            <Grid item md={7} bgcolor={"#F3FAFC"} borderRadius={5}>
              <Box p={5}>
                <RTLBox>
                  <form onSubmit={formk.handleSubmit}>
                    <List>
                      <ListItem>
                        <TextField
                          size="small"
                          label={i18n.t("AddNewClient.name")}
                          fullWidth
                          required
                          name="full_name"
                          value={formk.values.full_name}
                          onChange={formk.handleChange}
                          error={
                            formk.touched.full_name &&
                            Boolean(formk.errors.full_name)
                          }
                          helperText={
                            formk.touched.full_name && formk.errors.full_name
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          label={i18n.t("AddNewClient.email")}
                          fullWidth
                          size="small"
                          required
                          name="email"
                          value={formk.values.email}
                          onChange={formk.handleChange}
                          error={
                            formk.touched.email && Boolean(formk.errors.email)
                          }
                          helperText={formk.touched.email && formk.errors.email}
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          size="small"
                          name="mobile"
                          label={i18n.t("login.phone")}
                          fullWidth
                          value={formk.values.mobile}
                          onChange={formk.handleChange}
                          error={
                            formk.touched.mobile && Boolean(formk.errors.mobile)
                          }
                          helperText={
                            formk.touched.mobile && formk.errors.mobile
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <TextField
                          label={i18n.t("g.message")}
                          placeholder=""
                          fullWidth
                          name="message"
                          multiline
                          rows={5}
                          size="small"
                          value={formk.values.message}
                          onChange={formk.handleChange}
                          error={
                            formk.touched.message &&
                            Boolean(formk.errors.message)
                          }
                          helperText={
                            formk.touched.message && formk.errors.message
                          }
                        />
                      </ListItem>
                      <ListItem
                        sx={{
                          width: "100%",
                          justifyContent: "end",
                          display: "flex"
                        }}
                      >
                        <LoadingButton
                          type="submit"
                          loading={formk.isSubmitting}
                          variant="contained"
                        >
                          {i18n.t("login.send")}
                        </LoadingButton>
                      </ListItem>
                    </List>
                  </form>
                </RTLBox>
              </Box>
            </Grid>
            <Grid
              item
              md={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Box
                p={2}
                border={"2px solid #5494AC"}
                borderRadius={"97px 15px 97px 16px"}
                width={"100%"}
              >
                <img src={contactus} alt="Tajawal" width={"100%"} />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
