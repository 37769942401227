import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import {Close, Edit} from "@mui/icons-material";
import RTLBox from "../RTLBox";
import {mastrListAdmin} from "../../data/Lists";
import axios from "axios";
import {Store} from "../../context/DataStore";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";

export default function AddNewSuopervies({data, fun}) {
  const [info, setInfo] = useState(null);
  const {options} = Store();
  const {enqueueSnackbar} = useSnackbar();
  const [permissions, setPermissions] = useState(data ? data.permissions : []);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const {i18n} = useTranslation();
  // const [admin, setAdmin] = useState(null);
  const list = mastrListAdmin;
  const regex = /(05|01)[0-9]{8}/;

  const openHandler = async () => {
    if (data) {
      await getAdmin().then(() => {
        setOpen(true);
      });
    } else {
      setOpen(true);
    }
  };

  const validationSchema = yup.object({
    name: yup.string().min(2).required(i18n.t("AddNewClient.name_required")),
    phone: yup
      .string()
      .matches(regex, i18n.t("g.vaild_phone"))
      .max(10, i18n.t("g.vaild_phone_number"))
      .required(i18n.t("AddNewClient.phone_required")),
    email: yup.string().email().required(i18n.t("AddNewClient.email_required")),
    password: yup.string().required(i18n.t("AddNewClient.Password_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name:  "",
      phone:  "",
      email:"",
      password: data ? "*******" : "",
      userName: "",
      permissions: permissions
    },
    onSubmit: async (values) => {
      setLoading(true);
      const newAdmin = {
        name: values.name,
        mobile: values.phone,
        password: values.password === "*******" ? "" : values.password,
        permissions: permissions,
        email: values.email
      };
      if (!data) {
        console.log(newAdmin)
        await axios
          .post(`/admins/register`, newAdmin, {headers: options})
          .then((res) => {
            enqueueSnackbar(`${res.data.message}`, {variant: "success"});
            fun();
            formik.values = {};
            setOpen(false);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err.response);
            enqueueSnackbar(`${err?.response?.data?.message}`, {
              variant: "error"
            });
            console.log(err);
            setLoading(false);
          });
      } else {
        await axios
          .put(`admins/update/${data.id}`, newAdmin, {headers: options})
          .then((res) => {
            if (res.status === 200) {
              enqueueSnackbar(`${res.data.message}`, {variant: "success"});
              fun();
              formik.values = {};
              setOpen(false);
              setLoading(false);
            }
          })
          .catch((err) => {
            setLoading(false);
            enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
          });
      }
    }
  });
  function permissionsSet(val) {
    const isExist = permissions.find((ele) => ele === val);

    if (isExist) {
      const list = permissions.filter((ele) => ele !== val);
      setPermissions(list);
      formik.values.permissions = list;
    } else {
      const list = [...permissions].concat(val);
      setPermissions(list);
      formik.values.permissions = list;
    }
  }
  async function getAdmin() {
    await axios
      .get(`common/search-user/${data?.mobile}`, {headers: options})
      .then((res) => {
        // formik.values = {}
        setInfo(res.data.data[0]);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }
  useEffect(() => {
    formik.values.name = data?.name;
    formik.values.email = data?.email;
    formik.values.phone = data?.mobile;
  }, [data]);
  return (
    <Box>
      {data ? (
        <IconButton
          onClick={() => {
            openHandler();
          }}
        >
          {" "}
          <Edit color="primary" />{" "}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => {
            openHandler();
          }}
          sx={{
            bgcolor: "#CCDFF2",
            color: "#44A5FF",
            ":hover": {bgcolor: "#44A5FF", color: "#CCDFF2"}
          }}
        >
          {i18n.t("g.add_new_suopervies")}
        </Button>
      )}
      <Dialog open={open}>
        <Box p={1}>
          <IconButton
            onClick={() => {
              formik.handleReset(setOpen(false));
            }}
          >
            <Close />
          </IconButton>
        </Box>
          <RTLBox>
            <form onSubmit={formik.handleSubmit}>
              <Typography
                textAlign={"center"}
                // py={1}
                fontFamily={"Tajawal-b"}
                component={"h3"}
                variant="h5"
              >
                {data
                  ? i18n.t("g.add_eidt_suopervies")
                  : i18n.t("g.add_new_suopervies")}
              </Typography>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item md={4} my={0.5}>
                    <TextField
                      size="small"
                      fullWidth
                      value={formik.values.name }
                      variant="outlined"
                      label={i18n.t("AddNewClient.name")}
                      name="name"
                      inputProps={{type: "text"}}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      // placeholder={i18n.t("AddNewClient.name_hint")}
                    />
                  </Grid>
                  <Grid item md={4} my={0.5}>
                    <TextField
                      size="small"
                      fullWidth
                      label={i18n.t("AddNewClient.phone")}
                      value={formik.values.phone}
                      variant="outlined"
                      name="phone"
                      inputProps={{type: "text", maxLength: 10}}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                    />
                  </Grid>
                  <Grid item md={4} my={0.5}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      label={i18n.t("AddNewClient.email")}
                      value={formik.values.email}
                      name="email"
                      inputProps={{type: "email"}}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item md={6} my={0.5}>
                    <TextField
                      size="small"
                      fullWidth
                      value={formik.values.phone}
                      variant="outlined"
                      name="userName"
                      label={i18n.t("AddNewClient.userName")}
                      inputProps={{type: "number"}}
                      disabled
                      error={
                        formik.touched.userName &&
                        Boolean(formik.errors.userName)
                      }
                      helperText={
                        formik.touched.userName && formik.errors.userName
                      }
                    />
                  </Grid>
                  <Grid item md={6} my={0.5}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      defaultValue={formik.values.password}
                      name="password"
                      label={i18n.t("AddNewClient.password")}
                      inputProps={{type: "password"}}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormGroup>
                      <Grid container spacing={1}>
                        {list.map((ele, index) => (
                          <Grid item md={4} key={index}>
                            <FormControlLabel
                              control={
                                data ? (
                                  <Checkbox
                                    defaultChecked={
                                      data.permissions.find(
                                        (x) => x === ele.value
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                ) : (
                                  <Checkbox />
                                )
                              }
                              label={i18n.t(ele.title)}
                              onChange={() => {
                                permissionsSet(ele.value);
                              }}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"end"}
                  py={3}
                  px={2}
                >
                  <LoadingButton loading={loading}
                    sx={{
                      borderRadius: "15px 50px",
                      bgcolor: "#2391BC",
                      fontFamily: "Tajawal-b",
                      width: "120px"
                    }}
                    variant="contained"
                    type="submit"
                  >
                    {i18n.t("AddNewClient.add")}
                  </LoadingButton>
                </Box>
              </DialogActions>
            </form>
          </RTLBox>
      </Dialog>
    </Box>
  );
}
