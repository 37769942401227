import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoBox from "../components/admin/InfoBox";
import TablePartner from "../components/admin/TablePartner";
import { cancel_orders, checked_1, customer_review } from "../img";
import { Store } from "../context/DataStore";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function PartnerShip() {
  const [statistics, setStatistics] = useState("");
  const { options } = Store();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const partInfo = [
    {
      img: customer_review,
      title: "dashboard.salesinfo.number_of_seles",
      number: statistics?.total || 0,
      label: "dashboard.orders.info.partner_lable"
    },
    {
      img: checked_1,
      title: "dashboard.salesinfo.accept_orders",
      number: statistics?.accepted || 0,
      label: "dashboard.orders.info.partner_lable"
    },
    {
      img: cancel_orders,
      title: "dashboard.salesinfo.cancel_orders",
      number: statistics.refused || 0,
      label: "dashboard.orders.info.partner_lable"
    }
  ];
  const getStatistics = async () => {
    await axios
      .get("/common/user-statistics/partner", { headers: options })
      .then((res) => {
        setStatistics(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("userInfo");
          navigate("/login");
          return;
        }

      });
  };
  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Box mt={5} p={5}>
      <Container>
        <Typography
          py={5}
          component={"h4"}
          fontFamily={"Tajawal-b"}
          color={"#000"}
          variant="h5"
          fontWeight={900}
        >
          {i18n.t("g.partner_sets")}
        </Typography>
        <Grid container spacing={3}>
          {partInfo.map((ele, index) => (
            <Grid item md={4} xs={12} key={index}>
              <InfoBox ele={ele} />
            </Grid>
          ))}
        </Grid>
        <Typography
          py={5}
          component={"h4"}
          fontFamily={"Tajawal-b"}
          color={"#000"}
          variant="h5"
          fontWeight={900}
        >
          {i18n.t("g.partner")}
        </Typography>
      </Container>
      <TablePartner statistics={getStatistics} />
    </Box>
  );
}
