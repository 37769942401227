import {Close} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import RTLBox from "../RTLBox";
import {LoadingButton} from "@mui/lab";
import axios from "axios";
import {Store} from "../../context/DataStore";
import {enqueueSnackbar} from "notistack";

export default function AddNewClient({
  title,
  url,
  card,
  full,
  fun,
  statistics
}) {
  const {i18n} = useTranslation();
  const [files, setFiles] = useState("");
  const [loading, setloading] = useState(false);
  const {options} = Store();
  const formData = new FormData();
  const regex = /(05|01)[0-9]{8}/;
  const regexId = /[0-9]{10}/;

  const validationSchema = yup.object({
    name: yup.string().min(2).required(i18n.t("AddNewClient.name_required")),
    job: yup.string().required(i18n.t("AddNewClient.job_required")),
    rank: yup.string().min(2).required(i18n.t("AddNewClient.rank_required")),

    phone: yup
      .string()
      .matches(regex, i18n.t("g.vaild_phone"))
      .max(10, i18n.t("g.vaild_phone_number"))
      .required(i18n.t("AddNewClient.phone_required")),
    salary: yup.number().required(i18n.t("AddNewClient.salary_required")),
    // suports: yup.number().required(i18n.t("AddNewClient.suports_required")),
    totlePrice: yup.number().required(i18n.t("All_tables.totlePriceـrequired")),
    id: yup
      .string()
      .matches(regexId, i18n.t("g.vild_id"))
      .max(10)
      .required(i18n.t("AddNewClient.id_required")),

    brthday: yup.string().required(i18n.t("AddNewClient.brathday_required")),
    start_job: yup.string().required(i18n.t("AddNewClient.start_job_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: "",
      phone: "",
      salary: "",
      suports: 0,
      totlePrice: "",
      id: "",
      rank: "",
      brthday: "",
      start_job: "",
      job: "",
      files: ""
    },
    onSubmit: async (values) => {
      setloading(true);
      const newOrder = {
        name: values.name,
        identity_number: values.id,
        mobile: values.phone,
        born_in: values.brthday,
        job: values.job,
        rank: values.rank,
        date_join: values.start_job,
        salary: values.salary,
        total_commitment: values.totlePrice,
        support: values.suports
      };
      await axios
        .post(`${url}`, newOrder, {headers: options})
        .then((res) => {
          if (res.status === 200) {
            if (files.length > 0) {
              addFiles(res.data.data.id, res.data.message);
              closeHandler();
            } else {
              enqueueSnackbar(`${res.data.message}`, {variant: "success"});
              if (window.location.pathname.includes("/dashboard")) {
                fun();
                statistics();
              }
              closeHandler();
            }
          }
        })
        .catch((err) => {
          setloading(false);
          enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
          console.log(err);
        });
    }
  });
  const [open, setOpen] = useState(false);
  const closeHandler = () => {
    setOpen(false);
    formik.handleReset();
  };
  const openHandler = () => {
    setOpen(true);
  };
  function uploadHandler(e) {
    setFiles(e.target.files);
    formik.values.files = e.target.files;
  }
  const addFiles = async (id, message) => {
    Object.keys(files).map((file, index) => {
     return formData.append("file", files[index]);
    });
    await axios
      .post(`common/uploader/request?request_id=${id}`, formData, {
        headers: options
      })
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(`${message}`, {variant: "success"});
          setloading(false);
          formik.values = {};
          formik.handleReset();
          if (window.location.pathname.includes("/dashboard")) {
            fun();
            statistics();
          }
          closeHandler();
        }
      })
      .catch((err) => {
        setloading(false);
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      {card ? (
        <LoadingButton
          type="submit"
          fullWidth={full ? true : false}
          onClick={openHandler}
          variant="contained"
          sx={{
            borderRadius: "15px 50px",
            bgcolor: "#2391BC",
            fontFamily: "Tajawal-b"
          }}
        >
          {i18n.t("product_page.search_box.request")}
        </LoadingButton>
      ) : (
        <Button
          onClick={openHandler}
          variant="contained"
          size="small"
          sx={{
            bgcolor: "#CCDFF2",
            color: "#44A5FF",
            ":hover": {bgcolor: "#44A5FF", color: "#CCDFF2"}
          }}
        >
          {i18n.t(title)}
        </Button>
      )}
      <Dialog open={open} onClose={closeHandler} fullWidth>
        <Box p={2}>
          <Tooltip title="Close">
            <IconButton onClick={closeHandler}>
              <Close />
            </IconButton>
          </Tooltip>
        </Box>

        <DialogTitle
          sx={{
            my: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Typography
            fontFamily={"Tajawal-b"}
            component={"span"}
            variant="h6"
            borderRadius={2}
            textAlign={"center"}
            width={"100%"}
            p={1}
          >
            {i18n.t(title)}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box px={2}>
            <RTLBox>
              <form onSubmit={formik.handleSubmit}>
                <List>
                  <ListItem sx={{width: "100%", gap: 1}}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      gap={2}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        label={i18n.t("AddNewClient.name")}
                        name="name"
                        inputProps={{type: "text"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                        placeholder={i18n.t("AddNewClient.name_hint")}
                      />
                    </Box>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        sx={{color: "#ffffff"}}
                        label={i18n.t("AddNewClient.id")}
                        name="id"
                        inputProps={{type: "text", maxLength: 10}}
                        onChange={formik.handleChange}
                        error={formik.touched.id && Boolean(formik.errors.id)}
                        helperText={formik.touched.id && formik.errors.id}
                        placeholder={i18n.t("AddNewClient.id_hint")}
                      />
                    </Box>
                  </ListItem>
                  <ListItem sx={{width: "100%", gap: 1}}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      gap={2}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        label={i18n.t("AddNewClient.phone")}
                        variant="outlined"
                        name="phone"
                        inputProps={{type: "text", maxLength: 10}}
                        onChange={formik.handleChange}
                        maxRows={10}
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                        placeholder={"05"}
                      />
                    </Box>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <TextField
                        className="ss"
                        size="small"
                        fullWidth
                        label={i18n.t("AddNewClient.brathday")}
                        variant="outlined"
                        name="brthday"
                        value={formik.values.brthday}
                        // defaultValue={dayjs('02/10/2023')}
                        inputProps={{type: "date"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.brthday &&
                          Boolean(formik.errors.brthday)
                        }
                        helperText={
                          formik.touched.brthday && formik.errors.brthday
                        }
                      />
                    </Box>
                  </ListItem>
                  <ListItem sx={{width: "100%", gap: 1}}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      gap={2}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="job">
                          {i18n.t("AddNewClient.job")}{" "}
                        </InputLabel>
                        <Select
                          defaultValue={0}
                          size="small"
                          fullWidth
                          label={i18n.t("AddNewClient.job")}
                          name="job"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.job && Boolean(formik.errors.job)
                          }
                        >
                          <MenuItem value={0} disabled>
                            {i18n.t("g.choes_job")}
                          </MenuItem>
                          <MenuItem value={1}>{i18n.t("g.job_1")} </MenuItem>
                          <MenuItem value={2}> {i18n.t("g.job_2")} </MenuItem>
                        </Select>
                      </FormControl>

                    </Box>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        sx={{bgcolor: "#fff"}}
                        label={i18n.t("AddNewClient.salary")}
                        name="salary"
                        inputProps={{type: "number"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.salary && Boolean(formik.errors.salary)
                        }
                        helperText={
                          formik.touched.salary && formik.errors.salary
                        }
                        placeholder={i18n.t("AddNewClient.salary_hint")}
                      />
                    </Box>
                  </ListItem>
                  <ListItem sx={{width: "100%", gap: 1}}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      gap={2}
                    >
                      <TextField
                        size="small"
                        id="start_job"
                        fullWidth
                        variant="outlined"
                        label={i18n.t("AddNewClient.start_job")}
                        name="start_job"
                        inputProps={{type: "date"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.start_job &&
                          Boolean(formik.errors.start_job)
                        }
                        helperText={
                          formik.touched.start_job && formik.errors.start_job
                        }
                      />
                    </Box>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        label={i18n.t("AddNewClient.rank")}
                        name="rank"
                        inputProps={{type: "text"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.rank && Boolean(formik.errors.rank)
                        }
                        helperText={formik.touched.rank && formik.errors.rank}
                        placeholder={i18n.t("AddNewClient.rank_hint")}
                      />
                    </Box>
                  </ListItem>
                  <ListItem sx={{width: "100%", gap: 1}}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                      gap={2}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="job">
                          {i18n.t("All_tables.suports")}{" "}
                        </InputLabel>
                        <Select
                          defaultValue={0}
                          size="small"
                          fullWidth
                          label={i18n.t("All_tables.suports")}
                          name="suports"
                          onChange={formik.handleChange}
                          error={
                            formik.touched.suports &&
                            Boolean(formik.errors.suports)
                          }
                        >
                          <MenuItem value={1}>
                            {" "}
                            {i18n.t("All_tables.suport_by")}{" "}
                          </MenuItem>
                          <MenuItem value={0}>
                            {" "}
                            {i18n.t("All_tables.unsport")}
                          </MenuItem>
                        </Select>
                      </FormControl>

                    </Box>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        label={i18n.t("All_tables.totlePrice")}
                        name="totlePrice"
                        inputProps={{type: "number"}}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.totlePrice &&
                          Boolean(formik.errors.totlePrice)
                        }
                        helperText={
                          formik.touched.totlePrice && formik.errors.totlePrice
                        }
                      />
                    </Box>
                  </ListItem>
                  <ListItem>
                    <Box
                      gap={2}
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"start"}
                      alignItems={"start"}
                    >
                      <Button>
                        <label htmlFor="files">
                          {" "}
                          {i18n.t("AddNewClient.files")}
                        </label>
                      </Button>
                      <input
                        hidden
                        id="files"
                        size="small"
                        multiple
                        name="files"
                        type="file"
                        accept=".pdf"
                        onChange={(e) => {
                          uploadHandler(e);
                        }}
                        placeholder={i18n.t("AddNewClient.hint")}
                      />

                      {files && (
                        <Box>
                          <List>
                            {Object.values(files).map((ele, index) => (
                              <ListItem key={index}>{ele.name}</ListItem>
                            ))}
                          </List>
                        </Box>
                      )}
                    </Box>
                  </ListItem>
                  <ListItem></ListItem>
                </List>
                <Box width={"100%"} display={"flex"} justifyContent={"end"}>
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    sx={{
                      borderRadius: "15px 50px",
                      bgcolor: "#2391BC",
                      fontFamily: "Tajawal-b",
                      width: "120px"
                    }}
                    variant="contained"
                  >
                    {i18n.t("login.send")}
                  </LoadingButton>
                </Box>
              </form>
            </RTLBox>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
