import {
  Box,
  Container,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import ChartsBar from "../components/admin/ChartsBar";
import InfoBox from "../components/admin/InfoBox";
import axios from "axios";
import { Store } from "../context/DataStore";
import { customer, customer_review, project_management } from "../img";
import { useNavigate } from "react-router-dom";

export default function DashboardInfo() {
  const { options, userInfo } = Store()
  const navigate = useNavigate()


  const [statistics, setStatistics] = useState('')
  const usersData = [
    {
      img: customer,
      title: "dashboard.Home.user_data.orders",
      number: statistics?.requests || 0,
      label: "dashboard.Home.user_data.order_label"
    },
    {
      img: project_management,
      title: "dashboard.Home.user_data.sales",
      number: statistics?.sales||0,
      label: "dashboard.Home.user_data.users_label"
    },
    {
      img: customer_review,
      title: "dashboard.Home.user_data.servers",
      number: statistics.devs||0,
      label: "dashboard.Home.user_data.users_label"
    }
  ];
  const getStatistics = async () => {
    
    await axios.get('/common/user-statistics/admin', { headers: options }).then((res) => {
      setStatistics(res.data.data)
    }).catch((err) => {
      if (err.response.status === 401) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("userInfo");
        navigate("/");
        return;
      }
  })
  }
  const pageval = 1;
  const allowed = userInfo.permissions.find((x) => x === pageval);

  const { i18n } = useTranslation();
  useEffect(() => {
    if (userInfo.role === "admin" && !allowed) {
    }
  }, []);

  useEffect(() => {
    getStatistics()
  },[])
  return (
    <Box pt={5}>
      <Container>
        <Typography
          color={"#000"}
          component={"h2"}
          fontWeight={900}
          variant="h5"
          fontFamily={"Tajawal-b"}
        >
          {i18n.t("dashboard.Home.g.statistics")}
        </Typography>
        <Box mt={5}>
          <Grid container spacing={1} >
            {usersData.map((ele, index) => (
              <Grid item xs={12} md={4} key={index}  >
                <InfoBox key={index} ele={ele} statistics={statistics} />
              </Grid>
            ))}
            <Box
              mt={10}
              width={"100%"}
              bgcolor={"#fff"}
              borderRadius={2}
              p={5}
              sx={{boxShadow: "0px 0px 5px  gray"}}
              mb={4}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    width={"100%"}
                  >
                    <Typography
                      color={"#000"}
                      fontWeight={900}
                      component={"h3"}
                      variant="h5"
                      textAlign={'center'}
                    >
                      {i18n.t("dashboard.Home.Charts.site_Active")}
                    </Typography>
                    <Box>
                    </Box>
                  </Box>
                  <ChartsBar />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
