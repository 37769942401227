import { Cancel, Delete } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Store } from "../../context/DataStore";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";

export default function DeleteDailog({ title, mobile, message, fun, name,statistics }) {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useTranslation();
  const { options } = Store();
  const [open, setOpen] = useState(false);
  const [loadind, setLoadind] = useState(false);
  const deleteItems = async () => {
    setLoadind(true);
    await axios.get(`/common/delete-user/${mobile}`, { headers: options })
      .then((res) => {
        if (res.status === 200) {
          enqueueSnackbar(`${res.data.message}`, { variant: "success" });
          fun();
          statistics()
          setOpen(false);
          setLoadind(false);
        }
      })
      .catch((err) => {
        setLoadind(false);
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
      });
  };
  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <Delete color="error" />
      </IconButton>
      <Dialog open={open} fullWidth>
        <DialogTitle>
          <Typography>{title}</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color={"red"} variant="h6" fontWeight={900}>
            {i18n.t(message) + `${name}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={deleteItems}
            dir="ltr"
            startIcon={<Delete />}
            color="error"
            sx={{ fontSize: "16px" }}
            loading={loadind}
          >
            {i18n.t("delete_messages.delete")}
          </LoadingButton>
          <Button
            dir="ltr"
            onClick={() => setOpen(false)}
            startIcon={<Cancel />}
            color="primary"
            sx={{ fontSize: "16px" }}
          >
            {i18n.t("delete_messages.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
