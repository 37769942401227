import {useMediaQuery} from "@mui/material";
import axios from "axios";
import {createContext, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetData} from "../hooks/getData";
import { useSnackbar } from "notistack";

const DataStore = createContext();
export const DataStoreProvider = ({children}) => {
  const { i18n } = useTranslation();
  const [partnerData, setPartnerData] = useState([]);

  const {enqueueSnackbar , closeSnackbar}=useSnackbar()  
  const [show, setShow] = useState(true);
  const [sortData, setSetSortData] = useState(null);
  const [adminState, setAdminState] = useState(false);
  const [userInfo, setUserInfo] = useState(
    localStorage.userInfo ? JSON.parse(localStorage.userInfo) : null
  );
  const [userToken, setUserToken] = useState(
    localStorage.userToken ? JSON.parse(localStorage.userToken) : null
  );
  const [files, setFiles] = useState([]);

  const options = {
    "Accept-Language": i18n.language === "enUS" ? "en" : "ar",
    Authorization: userToken ? `Bearer ${userToken}` : null,
  };
  const showVaule = () => {
    if (window.location.pathname === "/") {
      setShow(true);
    } else {
      setShow(false);
    }
  };


  const deleteItems = async (id) => {
    await axios
      .delete(`/common/delete-user/${id}`, { headers: options})
      .then((res) => {
        enqueueSnackbar(`${res.data.message}`, { variant: "success" })
        
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});

      });
  };
  const changeLang = (lang) => {
    if (lang === "enUS") {
      localStorage.setItem("i18nextLn", JSON.stringify('arEG'));
      i18n.changeLanguage('arEG');
      document.getElementById("body").style.direction = "rtl";
    }
    if(lang === "arEG"){
      localStorage.setItem("i18nextLn", JSON.stringify('enUS'));
      i18n.changeLanguage('enUS')
      document.getElementById("body").style.direction = "ltr";
    }
  };

  const corectDir = (lang) => {
    if (lang === "enUS") {
      document.getElementById("body").style.direction = "ltr";
    }
    if(lang === "arEG"){
      document.getElementById("body").style.direction = "rtl";
    }
  };

  const mobileDiv = useMediaQuery("(max-width:600px)");
  return (
    <DataStore.Provider
      value={{
        mobileDiv,
        showVaule,
        show,
        setShow,
        userInfo,
        setUserInfo,
        changeLang,
        userToken,
        setUserToken,
        options,
        adminState,
        setAdminState,
        files,
        setFiles,
        deleteItems,
        partnerData, setPartnerData,sortData, setSetSortData,corectDir
       
      }}
    >
      {children}
    </DataStore.Provider>
  );
};
export const Store = () => {
  return useContext(DataStore);
};
