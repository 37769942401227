import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Store } from "../../context/DataStore";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import RTLBox from "../RTLBox";
import { LoadingButton } from "@mui/lab";

export default function DevEdit({ item, fun }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const [check, setCheck] = useState(false);
  const { options } = Store();
  const { i18n } = useTranslation();
  const regex = /(05|01)[0-9]{8}/;

  const validationSchema = yup.object({
    name: yup.string().min(2).required(i18n.t("login.companyName_required")),
    // company_type: yup.number().required(i18n.t("login.company_type_required")),
    email: yup.string().email().required(i18n.t("login.email_required")),
    mobile: yup
    .string()
    .matches(regex, i18n.t("g.vaild_phone"))
    .max(10, i18n.t("g.vaild_phone_number"))
    .required(i18n.t("login.Phone_required")),

    password: yup.string().required(i18n.t("login.Password_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      name: item.name,
      email: "",
      mobile: "",
      password: data ? "********" : ""
    },
    onSubmit: async (values) => {
      setLoading(true);
      const newCompany = {
        name: values.name,
        mobile: values.mobile,
        email: values.email,
        password: values.password === "********" ? "" : values.password
        //   company_type: values.company_type
      };

      await axios
        .put(`/real-estate-devs/update/${data.id}`, newCompany, {
          headers: options
        })
        .then((res) => {
          if (res.data.success === true) {
            enqueueSnackbar(`${res.data.message}`, { variant: "success" });
            fun();
            formik.initialValues = {};
            setOpen(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
          console.log(err);
        });
    }
  });
  const getItemsDetails = async () => {
    await axios
      .get(`/common/search-user/${item?.mobile}`, { headers: options })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  async function openHandler() {
    await getItemsDetails().then(() => {
      setOpen(true);
    });
  }
  useEffect(() => {
    if (data) {
      formik.values.email = data.email;
      formik.values.name = data.name;
      formik.values.id = data.id;
      formik.values.mobile = data.mobile;
    }
  }, [data]);

  return (
    <>
      <IconButton
        onClick={() => {
          openHandler();
        }}
      >
        {" "}
        <Edit color="primary" />{" "}
      </IconButton>
      <Dialog open={open} fullWidth>
        <Box p={3}>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
          <Typography
            textAlign={"center"}
            variant="h5"
            fontFamily={"Tajawal-b"}
          >
            {i18n.t("stsatus_titles.dev_Edit")}
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent>
              <RTLBox>
                <Grid container spacing={1}>
                  <Grid item md={6} my={1}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label={i18n.t("login.dev_name")}
                      value={formik.values.name || data?.name}
                      onChange={formik.handleChange}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                  <Grid item md={6} my={1}>
                    <TextField
                      fullWidth
                      id="mobile"
                      name="mobile"
                      label={i18n.t("login.phone")}
                      value={formik.values.mobile || data?.mobile}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={formik.touched.mobile && formik.errors.mobile}
                    />
                  </Grid>
                  <Grid item md={6} my={1}>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      inputProps={{ type: "email" }}
                      label={i18n.t("login.email")}
                      value={formik.values.email || data?.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item md={6} my={1}>
                    <TextField
                      fullWidth
                      inputProps={{ type: "password" }}
                      id="password"
                      name="password"
                      autoComplete="password"
                      label={i18n.t("login.password")}
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                </Grid>
              </RTLBox>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                sx={{
                  borderRadius: "15px 50px",
                  bgcolor: "#2391BC",
                  fontFamily: "Tajawal-b",
                  width: "120px"
                }}
                variant="contained"
                type="submit"
                loading={formik.isSubmitting}
              >
                {i18n.t("AddNewClient.edit")}
              </LoadingButton>
            </DialogActions>
          </form>
        </Box>
      </Dialog>
    </>
  );
}
