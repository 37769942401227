import {
  Box,
  Button,
  Container,
  Dialog,
  IconButton,
  List,
  ListItem,
  TextField
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { logo_1 } from "../img";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Store } from "../context/DataStore";

export default function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const {options} =Store()
  const { i18n } = useTranslation();
  const validationSchema = yup.object({
    email: yup.string().required(i18n.t("login.email_phone_required"))
  });
  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: ""
    },
    onSubmit: async (values) => {
      await axios
        .post("common/forget-password",values, {
          headers:options
        })
        .then((res) => {
          if (res.data.success === true) {
            enqueueSnackbar(`${res.data.message}`, { variant: "success" });
            closeHander();
          }
        })
        .catch((err) => {
          enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
        });
    }
  });


  function openHander() {
    setOpen(true);
  }
  function closeHander() {
    setOpen(false);
  }

  return (
    <Box>
      <Button variant="text" onClick={openHander}>
        {i18n.t("login.forgotPassword")}
      </Button>
      <Dialog open={open} fullWidth>
        <Box width={"100%"} p={2}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={3}
          >
            <img src={logo_1} alt="logo" width={100} />
            <IconButton onClick={closeHander}>
              <Close />
            </IconButton>
          </Box>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <List>
                <ListItem>
                  <TextField
                    fullWidth
                    name="email"
                    label={i18n.t("login.email_phone")}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email &&
                      Boolean(formik.errors.email)
                    }
                    helperText={
                      formik.touched.email && formik.errors.email
                    }
                  />
                </ListItem>
                <ListItem>
                  <LoadingButton variant="contained" type="submit">
                    {i18n.t("login.send")}
                  </LoadingButton>
                </ListItem>
              </List>
            </form>
          </Container>
        </Box>
      </Dialog>
    </Box>
  );
}
