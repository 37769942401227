import {
  Box,
  CircularProgress,
  InputBase,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AddNewSeller from "./AddNewSeller";
import axios from "axios";
import { Store } from "../../context/DataStore";
import DeleteDailog from "./DeleteDailog";
import StuteDailog from "./StuteDailog";
import { useSnackbar } from "notistack";
import { SearchOutlined } from "@mui/icons-material";

export default function SalesTable({ statistics }) {
  const [resSearch, setResSearch] = useState("");

  const [data, setData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useTranslation();
  const { options } = Store();
  const getData = async () => {
    setData(null);
    await axios
      .get("/sales/all", { headers: options })
      .then((res) => {
        if (res.status === 200) {
          setData(res.data.data);
          statistics()
        }
      })
      .catch((err) => {
        enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
        console.log(err);
      });
  };

  const changeHandel = async (e, p) => {
    setData(null)
    setResSearch(null)
    await axios
      .get(`/sales/all?page=${p}`, { headers: options })
      .then((res) => {
        setData(res.data.data);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (resSearch === "" || undefined) {
      getData();
    }
  }, [resSearch]);

  const getSearch = async (key) => {
    await axios
      .get(`/common/search-user/${key}`, { headers: options })
      .then((res) => {
        setResSearch(res.data);
      })
      .catch((err) => {
        setResSearch("");
      });
  };
  if (!data) {
    return(
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={60}>
     <CircularProgress />;

      </Box>
    )
  }
  return (
    <>
      <Box
        width={"100%"}
        bgcolor={"#D9E0E2"}
        display={"flex"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        py={1}
        my={3}
      >
        <Box
          borderRadius={2}
          width={"50%"}
          p={0.5}
          bgcolor={"#fff"}
          display={"flex"}
          alignItems={"center"}
          gap={2}
        >
          <SearchOutlined sx={{ color: "GrayText" }} />
          <InputBase
            fullWidth
            placeholder={i18n.t("search.titles.sales")}
            onChange={(e) => getSearch(e.target.value)}
          />
        </Box>
        <AddNewSeller fun={getData} statistics={statistics} />
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.id")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.name")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.phone")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.email")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.job")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.financing")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("login.pass")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("All_tables.stute")}</Typography>
              </TableCell>
              <TableCell align="center">
                <Typography>{i18n.t("AddNewClient.edit")}</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(resSearch || data)?.data?.map((ele, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Typography>{ele.identity_number}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{ele.name}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{ele.mobile}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{ele.email}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{ele.job_title}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{ele.investor}</Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>**********</Typography>
                </TableCell>
                <TableCell align="center">
                  <StuteDailog
                    statistics={statistics}
                    user_id={ele.id}
                    stutes={ele.status}
                    title={"stsatus_titles.sales"}
                    role={ele.role}
                  />
                </TableCell>
                <TableCell align="center">
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <AddNewSeller
                      items={ele}
                      fun={getData}
                      statistics={statistics}
                    />
                    <DeleteDailog
                      mobile={ele.mobile}
                      fun={getData}
                      message={"delete_messages.sales"}
                      name={ele.name}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
        >
          <Stack spacing={2} sx={{ direction: "ltr" }}>
            <Pagination
              count={data?.meta?.last_page}
              variant="outlined"
              shape="rounded"
              page={data?.meta?.current_page}
              onChange={changeHandel}
            />
          </Stack>
        </Box>
      </TableContainer>
    </>
  );
}
