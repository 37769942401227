import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import axios from "axios";
import {Store} from "../context/DataStore";
import {useSnackbar} from "notistack";

export default function SelectType({type, id}) {
  const {options} = Store()
  const {enqueueSnackbar} = useSnackbar()
  const {i18n} = useTranslation()
  const [newType, setNewType] = useState(type)
  const [types, setTypes] = useState(null)
  const getTypes = async () => {
    await axios.get("/lookups/item-status", {headers: options}).then((res) => {
      setTypes(res.data.data)
    }).catch((err) => {
      // enqueueSnackbar(`${err.response.data.message}`, {variant: "error"});
      console.log(err)
    })
  }
  const updateType = async (type) => {
    setNewType(type)
    await axios.put(`real-estate-devs/items/change_status`, {
      item_id: id,
      status_id: type,
    }, {headers: options}).then((res) => {
      enqueueSnackbar(`${res.data.message}`, {variant: "success"})
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getTypes()
  }, [])
  return (
   <FormControl fullWidth>
     <InputLabel id={"company_type_label"}>
       {i18n.t("g.items_type")}{" "}
     </InputLabel>
     <Select
      fullWidth={true}
    value={newType}
      id="company_type"
      name="company_type"
      labelId="company_type_label"
      label={i18n.t("g.items_type")}
      onChange={(e) => {
        updateType(e.target.value)
      }}
     >
       {types?.map((item, index) => (
        <MenuItem key={index} value={item.id}>{item.title} </MenuItem>
       ))}
     </Select>
   </FormControl>
  )
}
