import React, {useEffect} from "react";
import {Store} from "../context/DataStore";
import {Box, Container, Grid, Pagination, Stack} from "@mui/material";
import PartnersCards from "../components/PartnersCards";
import { useGetData } from "../hooks/getData";
import axios from "axios";

export default function Partners() {
  const {data ,setData}=useGetData('/partners/all')
  const {setShow , options} = Store();
  useEffect(() => {
    setShow(false);
  });
  const changeHandel = async (e, p) => {
    setData(null)
    
    await axios
      .get(`/partners/all?page=${p}`, {headers: options})
      .then((res) => {
        setData(res.data.data);
      });
  };

  return (
    <Box py={10}>
      <Container>

      <Grid container spacing={2}>
        {data?.data?.map((ele, index) => (
          <Grid item key={index} md={4} sm={6} xs={12} p={3}>
            <PartnersCards key={index} data={ele} />
          </Grid>
        ))}
        </Grid>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          py={2}
        >
          <Stack spacing={2} sx={{direction: "ltr"}}>
            <Pagination
              count={data?.meta?.last_page}
              variant="outlined"
              shape="rounded"
              onChange={changeHandel}
            />
          </Stack>
        </Box>

      </Container>

    </Box>
  );
}
