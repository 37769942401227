import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Steper from "../components/Steper";

export default function EmkanConfig() {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  function closeHandler() {
    setOpen(false);
  }
  function openHandler() {
    setOpen(true);
  }
  return (
    <div>
      <Button variant="contained" onClick={openHandler}>
        {i18n.t("Partens.order")}
      </Button>
      <Dialog open={open} onClose={closeHandler} fullWidth sx={{ py: 1 }}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <Steper />
        </DialogContent>
      </Dialog>
    </div>
  );
}
