import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Card, TextField, Typography, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import axios from "axios";
import { useSnackbar } from "notistack";

export default function Steper() {
  const { i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const steps = [
    i18n.t("stepper.titles.redeem_voucher"),
    i18n.t("stepper.titles.mobile_validation"),
    i18n.t("stepper.titles.thanks"),
  ];
  const [resData, setResData] = React.useState({
    id: "",
    amount: "",
    currency: "",
    status: "",
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const voucherValidation = yup.object({
    voucherCode: yup
      .string()
      .matches(
        /^[a-zA-Z0-9?><;,{}[\]\-_+=!@#$%\^&*|']*$/,
        i18n.t("stepper.forms.arabic_error")
      )
      .required(i18n.t("stepper.forms.voucher_code_r")),
    customerId: yup
      .number().typeError(i18n.t("stepper.forms.id_type_error"))
      .test(
        "len",
        i18n.t("stepper.forms.id_error"),
        (val) => val?.toString().length === 10
      )
      .required(i18n.t("stepper.forms.national_id_r")),
    applicationId: yup
      .number().typeError(i18n.t("stepper.forms.id_type_voucher"))
      .required(i18n.t("stepper.forms.application_id_r")),
  });
  const mobileValidation = yup.object({
    code: yup.number().typeError(i18n.t("stepper.forms.id_type_otp"))
    // .test(
    //   "len",
    //   i18n.t("stepper.forms.otp_error"),
    //   (val) => val?.toString().length === 4
    // )

    .required(i18n.t("stepper.forms.mobile_validationـr")),
  });

  const voucherForm = useFormik({
    validationSchema: voucherValidation,
    initialValues: {
      voucherCode: "",
      customerId: "",
      applicationId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await axios
        .post("/emkan/getVD", values)
        .then((res) => {
          setActiveStep(1);
          setLoading(false);
          console.log(res.data);
        })
        .catch((err) => {
          if (err.response.status === 502) {
            enqueueSnackbar(`حاول مرة اخرى`, { variant: "error" });
          } else {
            enqueueSnackbar(`${err.response.data.message}`, {
              variant: "error",
            });
          }
          setLoading(false);
        });
    },
  });
  const codeForm = useFormik({
    validationSchema: mobileValidation,
    initialValues: {
      code: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      await axios
        .post("/emkan/redeem", values)
        .then((res) => {
          setResData(res.data.voucher);
          enqueueSnackbar(`${res.data.message}`, { variant: "success" });
          setLoading(false);
          setActiveStep(3);
        })
        .catch((err) => {
          setLoading(false);
          enqueueSnackbar(`${err.response.data.message}`, { variant: "error" });
          console.log(err);
          setLoading(false);
        });
    },
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>
                <Typography mx={1}>{label}</Typography>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === 2 ? (
        <React.Fragment>
          <Box py={5}>
            <Card
              sx={{
                p: 5,
                maxWidth: "60%",
                marginX: "auto",
                border: "1px solid #5494AC",
                borderRadius: "10px",
                height: "300px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Typography
                align="center"
                fontWeight={700}
                variant="h5"
                component={"h1"}
              >
                {i18n.t("stepper.titles.thanks")}
              </Typography>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography>
                  {i18n.t("stepper.titles.voucher_amount")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    component={"span"}
                  >
                    {resData.amount} {resData.currency}{" "}
                  </Typography>
                </Typography>
                <Typography>
                  {i18n.t("stepper.titles.product_amount")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    color={"green"}
                    component={"span"}
                  >
                    {resData.amount} {resData.currency}{" "}
                  </Typography>
                </Typography>
                <Divider sx={{ borderBlockColor: "#5494AC", my: 2 }} />
                <Typography>
                  {i18n.t("stepper.titles.voucher")}
                  {" : "}
                  <Typography
                    variant="subtitle1"
                    component={"span"}
                    fontWeight={700}
                  >
                    {resData.id}
                  </Typography>
                </Typography>
                <Typography
                  component={"span"}
                  align="center"
                  bgcolor={"yellow"}
                >
                  {i18n.t("stepper.titles.redeemed")}
                </Typography>
              </Box>
            </Card>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {activeStep === 0 && (
            <Box my={5}>
              <form onSubmit={voucherForm.handleSubmit}>
                <Card
                  sx={{
                    p: 3,
                    maxWidth: "60%",
                    marginX: "auto",
                    border: "1px solid #5494AC",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={700}
                    variant="h5"
                    component={"h1"}
                  >
                    {i18n.t("stepper.titles.redeem_voucher")}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                      <label>{i18n.t("stepper.forms.voucher_code")} </label>
                      <TextField
                        name="voucherCode"
                        onChange={voucherForm.handleChange}
                        value={voucherForm.values.voucherCode}
                        error={
                          voucherForm.touched.voucherCode &&
                          Boolean(voucherForm.errors.voucherCode)
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {voucherForm.touched.voucherCode && (
                        <Typography variant="caption" color={"red"}>
                          {" "}
                          {voucherForm.errors.voucherCode}{" "}
                        </Typography>
                      )}
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <label> {i18n.t("stepper.forms.national_id")}</label>
                      <TextField
                        name="customerId"
                        onChange={voucherForm.handleChange}
                        value={voucherForm.values.customerId}
                        error={
                          voucherForm.touched.customerId &&
                          Boolean(voucherForm.errors.customerId)
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {voucherForm.touched.customerId && (
                        <Typography variant="caption" color={"red"}>
                          {" "}
                          {voucherForm.errors.customerId}{" "}
                        </Typography>
                      )}
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                      <label> {i18n.t("stepper.forms.application_id")}</label>
                      <TextField
                        name="applicationId"
                        onChange={voucherForm.handleChange}
                        value={voucherForm.values.applicationId}
                        error={
                          voucherForm.touched.applicationId &&
                          Boolean(voucherForm.errors.applicationId)
                        }
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {voucherForm.touched.applicationId && (
                        <Typography variant="caption" color={"red"}>
                          {" "}
                          {voucherForm.errors.applicationId}{" "}
                        </Typography>
                      )}
                    </Box>
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      type="submit"
                    >
                      <Typography color={"white"} fontWeight={600}>
                        {i18n.t("stepper.forms.check_out")}
                      </Typography>
                    </LoadingButton>
                  </Box>
                </Card>
              </form>
            </Box>
          )}
          {activeStep === 1 && (
            <Box my={5}>
              <form onSubmit={codeForm.handleSubmit}>
                <Card
                  sx={{
                    p: 5,
                    maxWidth: "60%",
                    marginX: "auto",
                    border: "1px solid #5494AC",
                    borderRadius: "10px",
                    height: "300px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    fontWeight={700}
                    variant="h5"
                    component={"h1"}
                  >
                    {i18n.t("stepper.titles.mobile_validation")}
                  </Typography>
                  <Box display={"flex"} flexDirection={"column"} gap={2}>
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      <label>
                        {i18n.t("stepper.forms.mobile_validation")}{" "}
                      </label>
                      <Box display={"flex"} flexDirection={"column"} gap={1}>
                        <TextField
                          name="code"
                          onChange={codeForm.handleChange}
                          value={codeForm.values.code}
                          error={
                            codeForm.touched.code &&
                            Boolean(codeForm.errors.code)
                          }
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                        {codeForm.touched.code && (
                          <Typography variant="caption" color={"red"}>
                            {" "}
                            {codeForm.errors.code}{" "}
                          </Typography>
                        )}
                      </Box>
                      <Typography>
                        {i18n.t("stepper.titles.voucher_amount")}
                        {" : "}
                        <Typography
                          variant="subtitle1"
                          color={"green"}
                          component={"span"}
                        >
                          {resData.amount} {resData.currency}{" "}
                        </Typography>
                      </Typography>
                    </Box>
                    <LoadingButton
                      variant="contained"
                      loading={loading}
                      type="submit"
                      sx={{ color: "#000", fontWeight: 600 }}
                    >
                      <Typography color={"white "} fontWeight={600}>
                        {i18n.t("stepper.send")}
                      </Typography>
                    </LoadingButton>
                  </Box>
                </Card>
              </form>
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
}
