import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoBox from "../components/admin/InfoBox";
import RealEstateUnits from "../components/admin/RealEstateUnits";
import { Store } from "../context/DataStore";
import DevTable from "../components/admin/DevTable";
import { useTranslation } from "react-i18next";
import { cancel_orders, checked_1, customer_review } from "../img";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Developers() {
  //common/user-statistics/developer
  const [statistics, setStatistics] = useState("");
  const { adminState, options } = Store();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const devInfo = [
    {
      img: customer_review,
      title: "dashboard.salesinfo.number_of_seles",
      number: statistics?.total || 0,
      label: "dashboard.orders.info.dev_lable"
    },
    {
      img: checked_1,
      title: "dashboard.salesinfo.accept_orders",
      number: statistics?.accepted || 0,
      label: "dashboard.orders.info.dev_lable"
    },
    {
      img: cancel_orders,
      title: "dashboard.salesinfo.cancel_orders",
      number: statistics.refused || 0,
      label: "dashboard.orders.info.dev_lable"
    }
  ];
  const getStatistics = async () => {
    await axios
      .get("/common/user-statistics/developer", { headers: options })
      .then((res) => {
        setStatistics(res.data.data);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.removeItem("userToken");
          localStorage.removeItem("userInfo");
          navigate("/");
          return;
        }
      });
  };
  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Box mt={5} p={5}>
      {adminState ? (
        <>
          {/* admin page */}
          <Container>
            <Typography
              py={5}
              component={"h4"}
              fontFamily={"Tajawal-b"}
              color={"#000"}
              variant="h5"
              fontWeight={900}
            >
              {i18n.t("g.div_sist")}
            </Typography>
            <Grid container spacing={1}>
              {devInfo.map((ele, index) => (
                <Grid item md={4} xs={12} key={index}>
                  <InfoBox ele={ele} />
                </Grid>
              ))}
            </Grid>
            <Typography
              py={5}
              component={"h4"}
              fontFamily={"Tajawal-b"}
              color={"#000"}
              variant="h5"
              fontWeight={700}
            >
              {i18n.t("g.all_div")}
            </Typography>
          </Container>
          <DevTable statistics={getStatistics} />
        </>
      ) : (
        <>
          {/* dev page */}
          <Box>
            <RealEstateUnits />
          </Box>
        </>
      )}
    </Box>
  );
}
