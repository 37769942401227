import {CleaningServices, Close, VisibilityOutlined} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {Store} from "../../context/DataStore";
import RTLBox from "../RTLBox";
import {useTranslation} from "react-i18next";
import {useGetData} from "../../hooks/getData";
import {enqueueSnackbar} from "notistack";

export default function OredrStateAdmin({row, url, statistics, fun}) {
  const {data, loading} = useGetData("/admins/sales-supervisor");
  const {i18n} = useTranslation();
  const [salesList, setSalesList] = useState(false);
  const [stateOrder, setStateOrder] = useState([]);
  const [state, setState] = useState(row?.status || "");
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(true);
  const {options, adminState, userInfo} = Store();
  const [loadingText, setLoadindText] = useState(false);
console.log(data)
  const openHandler = () => {
    setOpen(true);
  };
  const closeHandler = () => {
    setOpen(false);
  };

  const getSatat = async () => {
    await axios
      .get(`lookups/request-status`, {headers: options})
      .then((res) => {
        setStateOrder(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeHandelr = async (val) => {
    setLoadindText(true);
    if (data) {
      const id = data?.find((ele) => ele.name === val);
      // setValue(id.id);
      //console.log(id.id)
      await axios
        .put(
          `${url}`,
          {
            status_id: 4,
            admin_id: id.id,
            request_id: row.id
          },
          {headers: options}
        )
        .then((res) => {
          if (res.status === 200) {
            enqueueSnackbar(`${res.data?.message}`, {variant: "success"});
            setState(res.data?.data?.status);
            fun();
            statistics();
            setLoadindText(false);
            closeHandler();
            // setVal(res.data?.data?.status_id);
          }
        })
        .catch((err) => {
          setLoadindText(false);

          enqueueSnackbar(`${err?.response?.data?.message}`, {
            variant: "error"
          });
        });
    }
  };
  const changeState = async (id) => {
    if (id.target.value === "4") {
      setSalesList(true);
    } else {
      setSalesList(false);
      await axios
        .put(
          `${url}`,
          {
            status_id: id.target.value,
            user_id: row.id,
            request_id: row.id
          },
          {headers: options}
        )
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data?.data);
            enqueueSnackbar(`${res.data?.message}`, {variant: "success"});
            setState(res.data?.data?.status);
            statistics();
            closeHandler();
            // setVal(res.data?.data?.status_id);
          }
        })
        .catch((err) => {
          enqueueSnackbar(`${err?.response?.data?.message}`, {
            variant: "error"
          });
        });
    }
  };

  useEffect(() => {
    getSatat();
  }, []);
  return (
    <Box>
      {state ? (
        <Button onClick={() => setOpen(true)}>{state?.title}</Button>
      ) : (
        <IconButton onClick={openHandler}>
          <VisibilityOutlined />
        </IconButton>
      )}
      <Dialog open={open} fullWidth>
        <Box p={2}>
          <IconButton onClick={closeHandler}>
            <Close />
          </IconButton>
        </Box>
        <Typography
          py={1}
          variant="h6"
          fontFamily={"Tajawal-b"}
          textAlign={"center"}
        >
          {i18n.t("All_tables.status.title")}
        </Typography>
        <DialogContent>
          <FormControl sx={{width: "100%"}}>
            <RadioGroup
              // onChange={(e) => {
              //   changeState(e.target.value);
              // }}
              defaultValue={state ? state.id : null}
            >
              <RTLBox>
                <Grid container spacing={1}>
                  {stateOrder?.map((ele, index) => (
                    <Grid
                      item
                      md={6}
                      key={index}
                      sx={{order: ele.id === 4 ? 1 : 0}}
                    >
                      <FormControlLabel
                        value={ele.id}
                        control={<Radio />}
                        label={ele.title}
                        disabled={ele.id === 4 && !adminState ? true : false}
                        onChange={(e) => {
                          changeState(e);
                        }}
                      />
                    </Grid>
                  ))}
                  {salesList ? (
                    <Grid item md={6} sx={{order: 2}}>
                      {loadingText ? (
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          gap={1}
                          alignItems={"center"}
                        >
                          <CircularProgress />
                          <Typography>{i18n.t("g.tranfer")}</Typography>
                        </Box>
                      ) : (
                        <Autocomplete
                          disableClearable
                          freeSolo
                          id="combo-box-demo"
                          options={data?.map((ele) => ele.name) || []}
                          //   sx={{width: 300}}
                          loading={loading}
                          onChange={(e) => {
                            changeHandelr(e.target.innerText);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("g.choes_sales")}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      )}
                    </Grid>
                  ) : null}
                </Grid>
              </RTLBox>
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
